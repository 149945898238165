<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
// import { PhSpinner } from '@phosphor-icons/vue'
import { Button } from '~/components/ui/button'
import { useToast } from '~/components/ui/toast'
import type { AcceptOrDeclineCreditFacilityUpdateRequestPayload } from '~/types/apiPayload/creditFacility.payload'
const { $api, $event } = useNuxtApp()
const creditFacilityStore = useCreditFacilityStore()
const {
  creditFacilityUpdateRequest,
  facilityAmount,
  deactivationReasonsMapped,
} = storeToRefs(creditFacilityStore)

let whatUserDecided: string
const { toast } = useToast()

const { organisationId } = storeToRefs(useProfileStore())
// const showBanner = computed(
//   () => lastReasonId.value !== 'credit-facility-update-pending',
// )

const { mutate, isPending: isUpdating } = useMutation({
  mutationFn:
    $api.banking.creditFacilities.acceptOrDeclineCreditFacilityUpdateRequests,
  onSuccess() {
    const message =
      whatUserDecided === 'accept'
        ? 'Your Credit Facility has been updated'
        : 'You declined the Credit Facility update'
    toast({ title: 'Credit facility updated', description: message })
    $event('update:credit-facility', null)
  },
  onError() {
    toast({
      title: 'Error updating credit facility',
      description: 'Failed to update credit facility',
    })
  },
})

function updateRequest(decision: 'accept' | 'decline') {
  let status: AcceptOrDeclineCreditFacilityUpdateRequestPayload

  whatUserDecided = decision

  if (decision === 'accept') {
    status = { id: 'Accepted', status: 'Accepted' }
  } else {
    status = { id: 'Rejected', status: 'Rejected' }
  }
  mutate({ borrowerId: organisationId.value!, status })
}

watch(isUpdating, (newVal) => {
  if (newVal) {
    toast({
      title: 'Please wait...',
      description: 'Please wait while we process your credit facility update.',
    })
  }
})
</script>

<template>
  <div
    v-if="!!deactivationReasonsMapped['credit-facility-update-pending']"
    class="mx-4 border-b bg-[##E2E8F0] py-6 text-left text-sm leading-5 text-[#DC2626]"
  >
    <div
      v-if="
        !!deactivationReasonsMapped['credit-facility-update-pending'] &&
        'credit-facility-update-pending' &&
        creditFacilityUpdateRequest
      "
      class="flex flex-wrap justify-center gap-x-10 gap-y-4 text-primary"
    >
      <div>
        <p>
          Your active credit facility amount has been changed from
          <span class="font-bold">
            {{
              `${getCurrency(creditFacilityUpdateRequest.currency.code)}${Intl.NumberFormat().format(facilityAmount || 0)}`
            }}
          </span>

          to
          <span class="font-bold">
            {{
              `${getCurrency(creditFacilityUpdateRequest.currency.code)}${Intl.NumberFormat().format(
                creditFacilityUpdateRequest.requested_facility_amount,
              )}`
            }} </span
          >.
        </p>
        Payments cannot be made using the Credit Facility until the change has
        been acknowledged.
      </div>

      <div class="flex items-center gap-4">
        <Button :disabled="isUpdating" @click="updateRequest('accept')"
          >Accept</Button
        >
        <Button
          :disabled="isUpdating"
          variant="ghost"
          class="border border-destructive text-destructive"
          @click="updateRequest('decline')"
          >Decline</Button
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
a {
  font-weight: 700;
  text-decoration: underline;
}
</style>
